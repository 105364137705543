<template>
  <div>
    <a-card :title="$t('入库订单')">
      <a-row :gutter="[16, 8]">
        <a-col :span="24" style="max-width: 256px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <!-- <a-col :span="24" style="max-width: 200px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col> -->
        <a-col :span="24" style="max-width: 200px">
          <a-select v-model="searchForm.is_completed" :placeholder="$t('状态')" allowClear style="width: 100%" @change="search">
            <a-select-option key="false" value="false">{{ $t("进行中") }}</a-select-option>
            <a-select-option key="true" value="true">{{ $t("已完成") }}</a-select-option>
          </a-select>
        </a-col>

        <a-col :span="24" style="max-width: 256px">
          <a-input v-model="searchForm.search" :placeholder="$t('订单编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="max-width: 110px">
          <a-button type="primary" icon="search" :loading="queryLoading" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" style="max-width: 360px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">{{ $t("模板下载") }}</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">{{ $t("导入") }}</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
            <a-button @click="printReceiptOrder">{{ $t("批量打印") }}</a-button>
          </a-button-group>
        </a-col>

        <a-col style="max-width: 200px; float: right">
          <a-button type="primary" icon="plus" @click="addOrder">{{ $t("新增入库订单") }}</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="dataItems"
          :pagination="pagination"
          :loading="queryLoading"
          :rowSelection="{ selectedRowKeys, onChange: onSelectChange }"
          @change="tableChange"
        >
          <template slot="external_number" slot-scope="value, item, index">
            <a @click="viewDetail(item)">{{ value }}</a>
          </template>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button @click="viewDetail(item)">{{ $t("详情") }}</a-button>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />{{ $t("正在导入中, 请等待...") }}</div>
    </a-modal>
  </div>
</template>

<script>
import {
  stockInOrderList,
  receiptOrderPrint,
  receiptOrderTemplate,
  receiptOrderImport,
  receiptOrdersExport,
} from "@/api/order";
import { warehouseOption } from "@/api/option";
import moment from "moment";
import { getLodop } from "@/utils/LodopFuncs";
import JsBarcode from "jsbarcode";
import NP from "number-precision";
import { exportExcel } from "@/utils/excel";

export default {
  components: {},
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined, is_void: false },
      pagination: { current: 1, total: 0, pageSize: 16 },
      queryLoading: false,

      dataItems: [],
      warehouseItems: [],

      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "订单号",
          dataIndex: "external_number",
          width: 180,
          scopedSlots: { customRender: "external_number" },
        },
        {
          title: this.$t("状态"),
          dataIndex: "status",
          customRender: (value, item, index) => {
            return item.is_void ? this.$t("已作废") : item.is_completed ? this.$t("已完成") : this.$t("进行中");
          },
        },
        {
          title: this.$t("仓库名称"),
          dataIndex: "warehouse_name",
          customRender: (value, item, index) => {
            return item.warehouse_item.name;
          },
        },
        {
          title: this.$t("订单类型"),
          dataIndex: "order_type_name",
        },
        {
          title: this.$t("到货日期"),
          dataIndex: "arrival_date",
          width: 180,
        },
        {
          title: this.$t("创建时间"),
          dataIndex: "create_time",
          width: 180,
        },
        {
          title: this.$t("备注"),
          dataIndex: "remark",
          ellipsis: true,
        },
        {
          title: this.$t("操作"),
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      selectedRowKeys: [],
      selectionRows: [],
      importLoading: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      const searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.queryLoading = true;
      this.$router.push({ query: this.searchForm });
      stockInOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.queryLoading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date) {
      const [startDate, endDate] = date;
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    addOrder() {
      this.$router.push({ path: "/order/stock_in_order_create" });
    },
    viewDetail(item) {
      this.$router.push({ path: "/order/stock_in_order_detail", query: { id: item.id } });
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要导出的入库通知单!"));
        return false;
      }
      receiptOrdersExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, this.$t("入库通知单"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      receiptOrderTemplate()
        .then((resp) => {
          exportExcel(resp, this.$t("入库通知单导入模板"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        receiptOrderImport(data)
          .then(() => {
            this.$message.success(this.$t("导入成功"));
            this.list();
          })
          .catch((err) => {
            this.$message.error(err.response.data.detail);
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    printText(value) {
      return value != null || value != undefined ? value : "";
    },
    printReceiptOrder() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要打印的入库订单"));
        return false;
      }

      receiptOrderPrint({ ids: this.selectedRowKeys }).then((data) => {
        const LODOP = getLodop();
        LODOP.PRINT_INIthis.$t("");
        LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        for (let receiptOrderItem of data) {
          let canvas = document.createElement("canvas");
          JsBarcode(canvas, receiptOrderItem.external_number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
          let barcodeBase64 = canvas.toDataURL();

          let totalQuantity = 0;
          for (let item of receiptOrderItem.receipt_material_items) {
            totalQuantity = NP.plus(totalQuantity, item.total_quantity);
          }

          let pages = Math.ceil(receiptOrderItem.receipt_material_items.length / 14);
          for (let index = 1; index <= pages; index++) {
            let items = receiptOrderItem.receipt_material_items.slice((index - 1) * 14, index * 14);
            let tableRows = items.map((item) => {
              return `
<tr>
  <td>${this.printText(item.material_item.number)}</td>
  <td>${this.printText(item.material_item.name)}</td>
  <td>${this.printText(item.material_item.spec)}</td>
  <td>${this.printText(item.total_quantity)}</td>
  <td>${this.printText(item.batch_number)}</td>
  <td></td>
</tr>`;
            });

            let pageContent = `
<div style="padding: 8px">
  <div style="font-size: 36px; text-align: center">
    <span>${this.$t("入库订单")}</span>
    <span style="float: right;">${pages > 1 ? index : ""}</span>
  </div>

  <div style="margin-top: 8px; text-align: right">
    <img src="${barcodeBase64}" />
  </div>

  <div style="margin-top: 8px; display: flex">
    <div style="width: 34%">${this.$t("仓库")}: ${this.printText(receiptOrderItem.warehouse_item.name)}</div>
   <div style="width: 34%">${this.$t("创建时间")}: ${this.printText(receiptOrderItem.create_time)}</div>
    <div style="width: 33%">${this.$t("备注")}: ${this.printText(receiptOrderItem.remark)}</div>
  </div>

  <div style="margin-top: 16px; font-size: 14px">
    <table border="1" cellspacing="0" style="width: 100%; text-align: center">
      <tr>
        <th>${this.$t("产品编号")}</th>
        <th>${this.$t("产品名称")}</th>
        <th>${this.$t("规格")}</th>
        <th>${this.$t("应收数量")}</th>
        <th>${this.$t("批次编号")}</th>
        <th>${this.$t("推荐库位")}</th>
      </tr>
      ${tableRows.join("")}
      <tr>
        <td>${this.$t("合计")}:</td>
        <td></td>
        <td></td>
        <td>${this.printText(totalQuantity)}</td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
</div>`;

            LODOP.NewPage();
            LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
          }
        }

        LODOP.PREVIEW();
      });
    },
  },
  mounted() {
    this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
    this.list();

    warehouseOption({ page_size: 999999 }).then((data) => {
      this.warehouseItems = data.results;
    });
  },
};
</script>

<style scoped></style>
